import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import { StarFilled, AndroidFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import Papa from "papaparse";

const commonConfig = { delimiter: ";" };
const AvgGradeGoogle = () => {
  const [data, setData] = useState([]);
  
  function parseAvgGrade() {
    Papa.parse(
       "./data/AvgGrade.csv",
      {
        ...commonConfig,
        header: false,
        download: true,
        complete: (result) => {
          let x = [];
          //console.log('grade', result);

          result.data.forEach(element => {

            let d = element[0];
            let g = element[1].replace(",", '.') * 1;
            x.push({"Date": d, "count": g});
          })

          setData(x);
          //console.log('grade', x);
        }  
      }
    )
  }

  useEffect(() => {
    parseAvgGrade();
  }, []);


  const config = {
    data,
    padding: 'auto',
    xField: 'Date',
    yField: 'count',
    annotations: [
      {
        type: 'regionFilter',
        start: ['min', 'median'],
        end: ['max', '0'],
        color: '#F4664A',
      },
      {
        type: 'text',
        position: ['min', 'median'],
        content: 'Средняя',
        offsetY: -4,
        style: {
          textBaseline: 'bottom',
        },
      },
      {
        type: 'line',
        start: ['min', 'median'],
        end: ['max', 'median'],
        style: {
          stroke: '#F4664A',
          lineDash: [2, 2],
        },
      },
    ],
  };

  const content1 = (
  <div>
    <p>Когда недоступна оценка для конкретной страны, показывается эта оценка.</p>
    <p>Она рассчитывается на основе последних оценок вашего приложения во всех странах.</p>
  </div>
  );
  const content2 = (
  <div>
    <p>Средняя оценка со времени первой публикации приложения</p>
  </div>
  );
  return (
  <>
    <div style={{display: 'flex', gap: '60px', paddingBottom: '30px'}}>
      <div>
        <div style={{marginBottom: '10px'}}>Google Play</div>
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="30px" height="30px"><path d="M 7.125 2 L 28.78125 23.5 L 34.71875 17.5625 L 8.46875 2.40625 C 8.03125 2.152344 7.5625 2.011719 7.125 2 Z M 5.3125 3 C 5.117188 3.347656 5 3.757813 5 4.21875 L 5 46 C 5 46.335938 5.070313 46.636719 5.1875 46.90625 L 27.34375 24.90625 Z M 36.53125 18.59375 L 30.1875 24.90625 L 36.53125 31.1875 L 44.28125 26.75 C 45.382813 26.113281 45.539063 25.304688 45.53125 24.875 C 45.519531 24.164063 45.070313 23.5 44.3125 23.09375 C 43.652344 22.738281 38.75 19.882813 36.53125 18.59375 Z M 28.78125 26.3125 L 6.9375 47.96875 C 7.300781 47.949219 7.695313 47.871094 8.0625 47.65625 C 8.917969 47.160156 26.21875 37.15625 26.21875 37.15625 L 34.75 32.25 Z"/></svg>        
      </div>
      <div>
        <Popover content={content1} title="Информация" trigger="hover">
        <div style={{marginBottom: '10px'}}>Оценка</div>
        <h2 style={{margin: '0'}}><StarFilled /> 3.430</h2><small>+0.05</small>
        </Popover>
      </div> 
      <div>
        <Popover content={content2} title="Информация" trigger="hover">
        <div style={{marginBottom: '10px'}}>Средняя оценка</div>
        <h2 style={{margin: '0'}}><StarFilled /> 3.000</h2>
        <small>+0.015</small>
        </Popover>
      </div>  
      <div>
        <div style={{marginBottom: '10px'}}>Оценки:</div>
        <div><h2 style={{margin: '0'}}>968 </h2><small>+5</small></div>
      </div>
      <div>
        <div style={{marginBottom: '10px'}}>Оценки с отзывами:</div>
        <div><h2 style={{margin: '0'}}>492</h2> <small></small></div>
      </div>
    </div>

    <div style={{marginBottom: '10px'}}>Средняя оценка за все время (по месяцам)</div>
    <Line {...config} />
  </>
  );
};

export default AvgGradeGoogle;